/** @jsx jsx */
import { jsx } from "theme-ui";
// eslint-disable-next-line no-unused-vars
import React from "react";
import PropTypes from "prop-types";

const Overlay = ({ active }) => {
  return (
    <div
      sx={{
        transition: `opacity .25s ease-in-out`,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        opacity: active ? 0.5 : 0,
        display: active ? "" : "none",
        zIndex: 1,
        backgroundColor: "#000",
      }}
    />
  );
};

export default Overlay;
Overlay.defaultProps = {
  active: false,
};
Overlay.propTypes = {
  active: PropTypes.bool,
};
