/** @jsx jsx */
import { jsx } from "theme-ui";

const TwoColFlex = (props) => {
  const { blurb, content, reverse, leftSx, rightSx } = props;
  const lsx = leftSx || { flexGrow: 1, flexBasis: 50, minWidth: 300 };
  const rsx = rightSx || { flexGrow: 2, flexBasis: 300, minWidth: 300 };
  lsx.textAlign = "center";
  rsx.textAlign = `${reverse ? "right" : "left"}`;
  return (
    <div
      sx={{
        display: "flex",
        flexFlow: `wrap`,
        flexDirection: `${reverse ? "row-reverse" : "row"}`,
      }}
    >
      <div sx={lsx}>{blurb}</div>
      <main sx={rsx}>{content}</main>
    </div>
  );
};
export default TwoColFlex;
