/** @jsx jsx */
import { jsx, Button } from "theme-ui";
import Masonry from "react-masonry-css";

// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import _, { min } from "lodash";
import Img from "gatsby-image";
import Layout from "../layout";

import CatLink from "../components/Util/CatLink";
import { separator } from "../../defaults";
import LightHr from "../components/Util/LightHr";
import TwoColFlex from "../components/Util/TwoColFlex";
import Overlay from "../components/Util/Overlay";
import Modal from "../components/Util/Modal";
import ArraySplit from "../components/Util/ArraySplit";

const Gallery = ({ data, pageContext, numMonthsPerGroup }) => {
  const { photoCategories, category } = pageContext;
  const photoEdges = data.allMarkdownRemark.edges;
  const [activePhoto, setActivePhoto] = useState(photoEdges[0].node.id);
  const [modalOpen, setModalOpen] = useState(false);

  const nodeMap = {};
  photoEdges.forEach((edge) => {
    nodeMap[edge.node.id] = edge.node;
  });

  const imageMap = {};
  data.allFile.edges.forEach((edge) => {
    imageMap[edge.node.relativePath] = edge.node.childImageSharp;
  });

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };
  const byMonth = {};
  const monthStep = numMonthsPerGroup || 12;
  const monthGroups = _.range(monthStep, monthStep + 12).map(
    (v) => v - (v % monthStep) - monthStep + 1
  );
  const getStartEndMonth = (month) => {
    const startMonth = min([month, monthGroups[month - 1]]);
    let endMonth = startMonth;
    let i = month - 1;
    while (i < 12 && monthGroups[i] === startMonth) {
      endMonth = i + 1;
      i += 1;
    }
    return [startMonth, endMonth];
  };
  photoEdges.forEach((edge) => {
    const { date } = edge.node.frontmatter;
    const year = parseInt(date.slice(0, 4), 10);
    const month = parseInt(date.slice(5, 7), 10);
    const months = getStartEndMonth(month);
    const groupKey = `${months[0]} / ${year} — ${months[1]} / ${year}
    `;
    if (groupKey in byMonth) {
      byMonth[groupKey].push(edge);
    } else {
      byMonth[groupKey] = [edge];
    }
  });

  const modalImage = (
    <Img
      // style={{ width: "50%" }}
      fluid={imageMap[nodeMap[activePhoto].frontmatter.cover].fluid}
      alt=""
    />
  );
  const activeDate = new Date(nodeMap[activePhoto].frontmatter.date);
  const modalDesc = (
    <div sx={{ paddingLeft: "10px" }}>
      <p>
        <b>Date: </b> 
        {activeDate.toDateString()}
      </p>
      <p>{nodeMap[activePhoto].frontmatter.summary}</p>
    </div>
  );
  const photoCategoryLinks = [
    CatLink("all", "/photos", typeof category === "undefined"),
  ];
  photoCategories.forEach((cat) => {
    photoCategoryLinks.push(
      CatLink(cat, `/photos/${_.kebabCase(cat)}`, cat === category)
    );
  });
  return (
    <>
      <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <TwoColFlex
          leftSx={{ flexGrow: 1, flexBasis: 200, minWidth: 300 }}
          right={{ flexGrow: 1, flexBasis: 200, minWidth: 300 }}
          blurb={modalImage}
          content={modalDesc}
        />
        <Button
          as="button"
          sx={{
            cursor: "pointer",
            position: "fixed",
            top: "calc(0% + 10px)",
            left: "calc(0% + 10px)",
            backgroundColor: "secondary",
          }}
          onClick={() => setModalOpen(false)}
          onKeyPress={() => {
            setModalOpen(false);
          }}
        >
          X
        </Button>
      </Modal>

      {modalOpen && <Overlay active={modalOpen} />}
      <Layout>
        <Helmet title={`Gallery | ${category || "photos"}`} />
        {/* <SEO /> */}
        <div sx={{ fontSize: 2 }}>
          <ArraySplit arr={photoCategoryLinks} sep={separator} />
        </div>
        <div>
          {_.sortBy(
            Object.keys(byMonth),
            (k) => -1 * parseInt(k.slice(3, 8) + k.slice(0, 1), 10)
          ).map((key, i) => (
            <div key={key}>
              <div sx={{ paddingY: 1 }}>
                <b>{key}</b>
              </div>
              <Masonry
                key={key}
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {byMonth[key].map((edge) => (
                  <div
                    key={edge.node.id}
                    sx={{ cursor: "pointer" }}
                    className={
                      edge.node.id === activePhoto ? "active-photo" : ""
                    }
                    onClick={() => {
                      setActivePhoto(edge.node.id);
                      setModalOpen(true);
                    }}
                    onKeyPress={() => {
                      setActivePhoto(edge.node.id);
                      setModalOpen(true);
                    }}
                    role="button"
                    tabIndex="0"
                    // sx={{ width: activePhoto === edge.node.id ? "100%" : "auto" }}
                  >
                    {edge.node.id in nodeMap &&
                      edge.node.frontmatter.cover in imageMap && (
                        <Img
                          key={edge.node.id}
                          fluid={imageMap[edge.node.frontmatter.cover].fluid}
                          alt=""
                        />
                      )}
                  </div>
                ))}
              </Masonry>
              {i !== Object.keys(byMonth).length - 1 && <LightHr />}
            </div>
          ))}
        </div>
      </Layout>
    </>
  );
};

export default Gallery;

/* eslint no-undef: "off" */
export const query = graphql`
  query ListPhotosQuery(
    $imageList: [String]
    $filter: MarkdownRemarkFilterInput
    $sort: MarkdownRemarkSortInput
  ) {
    allFile(filter: { relativePath: { in: $imageList } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          absolutePath
          relativePath
          relativeDirectory
        }
      }
    }
    allMarkdownRemark(filter: $filter, sort: $sort) {
      edges {
        node {
          id
          fields {
            slug
            date
            html_summary
            shown_on_home
            shown_on_basepath
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
            live_url
            source_url
            summary
            shown_on_home
            shown_on_basepath
            category
          }
        }
      }
    }
  }
`;
