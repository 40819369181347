/** @jsx jsx */
import { jsx } from "theme-ui";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Modal component that opens and closes
 */
const Modal = ({ modalOpen, setModalOpen, children }) => {
  const modalOpenClass = "open-modal";
  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modalOpen]);
  function useModalCloser(ref) {
    useEffect(() => {
      /**
       * Close open modal when outside click is detected
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          ref.current.classList.contains(modalOpenClass)
        ) {
          setModalOpen(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useModalCloser(wrapperRef);
  return (
    <div
      ref={wrapperRef}
      className={modalOpen && modalOpenClass}
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: `translate(-50%,-50%)`,
        visibility: modalOpen ? "visible" : "hidden",
        opacity: modalOpen ? 1 : 0,
        minWidth: "60%",
        maxHeight: "75vh",
        zIndex: 2000,
        backgroundColor: `background`,
        // borderColor: `secondary`,
        // borderWidth: `3px`,
        // borderStyle: "solid",
        padding: "1rem",
        overflowY: "scroll",
        transition: `opacity .4s ease-in-out`,
      }}
    >
      {children}
    </div>
  );
};

export default Modal;

Modal.defaultProps = { modalOpen: false, setModalOpen: () => {} };

Modal.propTypes = {
  /** State of modal being open */
  modalOpen: PropTypes.bool,
  /** setStateAction for setting modalOpen */
  setModalOpen: PropTypes.func,
};
